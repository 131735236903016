.cardMods {
    width: 75%;
    margin-top: 5%;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    p {
        padding-left: 25px;
    }
    a {
        padding-Left:25px;
    }
    .Ul-List {
        li {
            padding-right: 20px;
        }
        display: flex;
        flex-wrap: wrap;
    }
}