.Home{
    .HomeItem { 
    text-align: left;
    padding-left: 10px;
    padding-left: 10%;
    padding-right: 10%;
    
    
    .flexList {
        @media screen and (max-width: 650px) {
                flex-direction: column;
                justify-content: left;
            
        }
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        padding-right: 40px;
        
        li {
             padding-right: 20px;
        }

    }
}
    
    

    p ul {
        font-size: 20px;
        
    }
    .BioPic{
        margin-top: -150px;
        position: relative;
    }

    .Education{
       
        padding-top: 20px;
        

       

    }
}