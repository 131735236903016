.Footer {
    .icons{
       .icon-Padding {
            padding-right: 40px;
       }
    }
    display: flex;
    width: 100%;
    background-color: #343a40;
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    bottom: 0;
    justify-content: space-between;
    padding-left: 35px;
    padding-right: 35px;
}